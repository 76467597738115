export enum UIRoutes {
    Dashboard = 'home',
    Shipment = 'shipment',
    Device = 'device',
    DeviceUpload = 'device-upload',
    Category = 'category',
    Product = 'product',
    AgentApproval = 'agent-approval',
    UserApproval = 'user-approval',
    User = 'user',
    BusinessLocation = 'business',
    Report = 'report',
    Role = 'role',
    Stakeholder = 'stakeholder',
    Escalation = 'escalation',
    EscalationHistory = 'history',
    ReportSchedule = 'report-schedule',
    Agent = 'agents',
    // AgentReassignment = 'agent-reassignment',
    RepairApproval = 'agent-repair-approval',
    UserActivityLog = 'activity-log',
    AgentReassignmentApproval = 'agent-reassignment-approval',
}