export enum UIs {
    Dashboard = 'Dashboard',
    Shipment = 'Shipment',
    Device = 'Device',
    DeviceUpload = 'DeviceUpload',
    Category = 'Category',
    Product = 'Product',
    AgentApproval = 'AgentApproval',
    UserApproval = 'UserApproval',
    User = 'User',
    BusinessLocation = 'BusinessLocation',
    Report = 'Report',
    ReportSchedule = 'ReportSchedule',
    SingleReportSchedule="Single",
    Role = 'Role',
    Stakeholder = 'Stakeholder',
    Escalation = 'Escalation',
    EscalationHistory = 'EscalationHistory',
    EscalationCycle = 'EscalationCycle',
    Agents = 'Agents',
    AgentsReassignment = 'AgentsReassignment',
    RepairApproval = 'RepairApproval',
    UserActivityLog = 'UserActivityLog',
    AgentReassignmentApproval = 'AgentReassignmentApproval',
}